<template>
  <div>
    <div
      v-loading='loading'
      element-loading-text='玩命加载中……'
      element-loading-spinner='el-icon-loading'>
      <el-table
        ref='elTable'
        :data='tableData'
        v-bind='$attrs'
        highlight-current-row
        :row-key='rowKey'
        v-on='$listeners'>
        <slot name='first-column'></slot>
        <slot name='second-column'></slot>
        <el-table-column
          v-if='showIndex'
          width='50'
          label='序号' header-align='center' align='center'>
          <template slot-scope='scope'>
            <span>{{ offset + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for='(v,k) in columns'
          :key='k'
          :prop="v.field?v.field:typeof(v.value)=='string'?v.value:''"
          :label='v.title'
          :min-width='v.width'
          :show-overflow-tooltip='true'
          :header-align="v.header_align?v.header_align:'center'"
          :align="v.align?v.align:'center'"
          :sortable='v.sortable?v.sortable:false'>
          <template slot-scope='scope'>
            <span v-if="typeof(v.value)=='string'">
              <span v-if="v.type == 'image'">
                  <el-image
                    :src='scope.row[v.value]' :alt='scope.row[v.value]' style='width: 100%;height: 100%' width='40px'
                    fit='scale-down'
                    :preview-src-list='[scope.row[v.value]]'>
                    <div slot='error' class='image-slot'>
                      <i class='el-icon-picture-outline'></i>
                    </div>
                  </el-image>
              </span>
              <span v-else-if='v.value_alias'>
                <el-tooltip effect='dark' :content='scope.row[v.value_alias]' placement='top-start'
                            popper-class='ape-table-tooltip' v-if='scope.row[v.value]'>
                  <span v-html='scope.row[v.value]'></span>
                </el-tooltip>
                 <span v-else v-html='scope.row[v.value_alias]'></span>
              </span>
              <span v-else v-html='scope.row[v.value]'></span>
            </span>
            <span v-else-if="typeof(v.value)=='object'">
              <span v-for='(v1,k1) in v.value' :key='k1'>
                <template v-if='v.key'>
                  <template v-for='(item, idx) in scope.row[v.key]'>
                    <el-tooltip :key='idx' effect='dark' placement='top-start'>
                      <div slot='content' v-html='item[v1.value_alias]'></div>
                      <p class='is-value'>{{ item[v1.value] }}</p>
                    </el-tooltip>
                  </template>
                </template>
                <span class='more-info-display'
                      v-else-if='scope.row[v1.value] || scope.row[v1.value_alias] || scope.row[v1]'>
                  <span class='is-value' v-if="typeof(v1)=='string'" v-html='scope.row[v1]'></span>
                  <span class='is-label' v-if="typeof(v1)=='object' && v1.label"
                        :style="{width:v1.width?parseInt(v1.width)+'px':'72px'}" v-html='v1.label'></span>
                  <template v-if='v1.value_alias && v1.value'>
                    <el-tooltip effect='dark' placement='top-start' popper-class='ape-table-tooltip'
                                v-if='scope.row[v1.value]'>
                      <div slot='content' v-html='scope.row[v1.value_alias]'></div>
                      <span class='is-value' v-if="typeof(v1)=='object'" v-html='scope.row[v1.value]'></span>
                    </el-tooltip>
                    <span class='is-value' v-else v-html='scope.row[v1.value_alias]'></span>
                  </template>
                  <template v-else>
                    <span class='is-value' v-if="typeof(v1)=='object'" v-html='scope.row[v1.value]'></span>
                  </template>
                </span>
              </span>
            </span>
          </template>
        </el-table-column>
        <slot />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MTable',
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    rowKey: {
      type: String,
      default() {
        return ''
      }
    },
    columns: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false
    },
    showIndex: {
      default: false
    },
    offset: {
      default: 0
    }
  }
}
</script>

<style scoped>

</style>
